import * as React from "react"
import AppLayout from "../../components/app/app-layout"
import Seo from "../../components/seo";
import {graphql} from "gatsby"
import {useEffect} from "react";

const formUrl =
  "https://docs.google.com/forms/d/1TZY7OWMhIBBulkeSVDLPbSDe5abstwG_XgZReA4SHSg/formResponse"

const QuizKingContact = () => {
  const title = "お問い合わせ";
  const productName = "小学生クイズ王"

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // 入力フィールドの文字数制限（5万字程度）あるが、システム運用でそこまで長くならないので特に対応していない
      const urlParams = new URLSearchParams(window.location.search).toString()
      const hiddenField = document.getElementById('hidden-other-info')
      if (hiddenField) {
        hiddenField.value = urlParams
      }
    }
  }, [])

  return (
    <AppLayout isShownHeader={false}>
      <Seo title={title} isIndex={false} />
      <article className="container page-article-main contact-page">
        <header>
          <h1>{title}</h1>
        </header>

        <section>
          <p>
            いつも{productName}をご利用いただきありがとうございます。
            <br />
            下記項目のご記入をお願い致します。
          </p>

          <form action={formUrl} method="POST" id="js-app-contact-form-wrap">
            <div>
              <label htmlFor="entry.518793796">お名前【必須】</label>
              <input
                type="text"
                name="entry.518793796"
                id="entry.518793796"
                data-contact-type="js-contact-name"
                required
              />
            </div>

            <div>
              <label htmlFor="entry.1584384073">メールアドレス【必須】</label>
              <input
                type="email"
                name="entry.1584384073"
                id="entry.1584384073"
                data-contact-type="js-contact-email"
                required
              />
            </div>

            <div className="divider"></div>

            {/* google-formの集計の都合上、valueは翻訳しない */}
            <div>
              <label htmlFor="entry.993209168">1）お問い合わせの種類【必須】</label>
              <select
                name="entry.993209168"
                id="entry.993209168"
                data-contact-type="js-contact-menu"
                required
              >
                <option
                  data-contact-placeholder={""}
                  value="質問"
                >
                  質問
                </option>
                <option
                  data-contact-placeholder={""}
                  value="要望"
                >
                  要望
                </option>

                <option
                  data-contact-placeholder={""}
                  value="エラー報告"
                >
                  エラー報告
                </option>
                <option
                  data-contact-placeholder={""}
                  value="クイズについて"
                >
                  クイズについて
                </option>
                <option
                  data-contact-placeholder={""}
                  value="その他"
                >
                  その他
                </option>
              </select>
            </div>

            <div>
              <label htmlFor="entry.424111308">2）お問い合わせ内容【必須】</label>
              <textarea
                rows="9"
                name="entry.424111308"
                id="entry.424111308"
                data-contact-type="js-contact-body"
                required
              ></textarea>
            </div>

            <div style={{display: 'none'}}>
              <label htmlFor="entry.1612964080">3）その他【任意】</label>
              <textarea
                rows="9"
                name="entry.1612964080"
                id="entry.1612964080"
                data-contact-type="js-contact-body"
                placeholder={"追加の情報がある場合はご記入ください"}
              ></textarea>
            </div>

            <input
              type="hidden"
              name="entry.1612964080"
              id="hidden-other-info"
            />

            <p>返信をお約束することは出来ませんが、すべての内容を確認し、改善に努めてまいります。</p>

            <p>今後とも{productName}を宜しくお願いいたします。</p>

            <p>※ご注意：携帯電話会社の迷惑メールフィルターなどを利用している場合、返答が受信できない場合がございます。</p>

            <p>@dan-ran.co.jpからのメールを受け取れるように設定していただいた上でお問い合わせください。</p>

            <div className="static-page-button-wrap">
              <button
                type="submit"
                className="static-page-button"
                id="js-app-contact-form-submit-button"
              >
                送信
              </button>
            </div>

            <div id="js-notice-api-error"></div>
          </form>
        </section>
      </article>
    </AppLayout>
  )
}

export default QuizKingContact

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
